<template>
  <div :id="`${header.key}-table-cell`" class="flex" :class="divClasses">
    <div
      v-if="props.statusIndicator"
      class="w-3 h-3 rounded-full"
      :class="props.statusColorMap[props.item[props.header.key]]"
    ></div>
    <span
      v-else
      :class="`truncate max-w-[${props.width}] text-${props.textColor} ${props.spanClasses}`"
      >{{ item[header.key] != undefined ? formatValue(item[header.key]) : '' }}
    </span>
    <StatsValue
      v-if="
        typeof item[header.key] == 'object' &&
        item[header.key].additionalPercentage
      "
      :font-classes="'font-extrabold text-sm'"
      :value="item[header.key].additionalPercentage.value.toString()"
      :percentage="true"
      :arrow-ternary="item[header.key].additionalPercentage.arrowTernary"
      :color-ternary="item[header.key].additionalPercentage.colorTernary"
    >
    </StatsValue>
  </div>
</template>

<script setup>
import { watch } from 'vue';
import { ref } from 'vue';
import { formatCurrency } from '@/utils/helpers';
import StatsValue from '../Misc/StatsValue.vue';

function formatValue(cell) {
  //Cases where cells are empty because they are the expand column
  if (cell == undefined) {
    return;
  }
  let newValue = cell.value ? cell.value : cell;
  switch (cell.format ? cell.format : props.cellFormat) {
    case 'currency':
      newValue = formatCurrency('en-US', false).format(
        isNaN(newValue) ? 0 : newValue
      );
      break;
    default:
    //Do nothing
  }
  if (props.header.numbered) {
    newValue = (props.index + 1).toString() + '. ' + newValue;
  }
  return newValue;
}

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  index: {
    type: Number,
    default: 0,
  },
  header: {
    type: Object,
    default: () => {},
  },
  width: {
    type: String,
    default: '15em',
  },
  textColor: {
    type: String,
    default: 'black',
  },
  spanClasses: {
    type: String,
    default: '',
  },
  divClasses: {
    type: String,
    default: '',
  },
  statusIndicator: {
    type: Boolean,
    default: false,
  },
  statusColorMap: {
    type: Object,
    default: () => {},
  },
  cellFormat: {
    type: String,
    default: null,
  },
});

const input = ref(String(props.item[props.header.key]));

watch(
  () => props.item,
  () => {
    input.value = String(props.item[props.header.key]);
  }
);
</script>
