import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';
import {
  FAKE_WORK_GAP_DATA,
  fetchAndSortWorkGapData,
} from '@/utils/ogFakeData';
import { apiPost } from '@/utils/api';
import getPath from '@/utils/getPath';

const startDateObj = new Date();
startDateObj.setMonth(startDateObj.getMonth() - 7);
startDateObj.setDate(1); // Set to the 1st day of that month
const defaultStartDate = startDateObj.toLocaleDateString('en-US', {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
});

const defaultEndDate = new Date().toLocaleDateString('en-US', {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
});

export const useWorkGapStore = defineStore('workgap', {
  state: () => ({
    searchLimit: 15,
    searchPage: 1,
    searchDirection: 'asc',
    searchField: 'user',
    totalRows: 0,
    fakeWorkGapData: [],

    startDate: defaultStartDate,
    endDate: defaultEndDate,
    defaultStartDate: defaultStartDate,
    defaultEndDate: defaultEndDate,

    selectedUsers: [],
    selectedActivityGap: 0,
    OGFakeWorkGapData: FAKE_WORK_GAP_DATA,
    filtersApplied: [],
    currentMonth: '',
    lastMonth: '',
    sixMonthRange: '',
    monthlyTotal: 0,
    sixMonthAvg: 0,
    monthlyVS6MonthAverageCount: {
      value: 0,
      colorTernary: 0,
      caretTernary: 0,
    },
    monthlyVS6MonthAveragePercent: {
      value: 0,
      colorTernary: 0,
      caretTernary: 0,
    },
    monthlyInsights: [],
    highOccurrenceDays: [],
    mostOccurrenceUsers: [],
    baseChartData: [
      { month: '4', activityGap: '1.5 - 3 hours', user: 'Diane' },
      { month: '5', activityGap: '3+ hours', user: 'Casey' },
      { month: '8', activityGap: '1.5 - 3 hours', user: 'Casey' },
      { month: '9', activityGap: '3+ hours', user: 'Sam' },
      { month: '10', activityGap: '1.5 - 3 hours', user: 'Robert' },
      { month: '4', activityGap: '1.5 - 3 hours', user: 'Diane' },
      { month: '5', activityGap: '3+ hours', user: 'Casey' },
      { month: '8', activityGap: '1.5 - 3 hours', user: 'Casey' },
      { month: '9', activityGap: '3+ hours', user: 'Sam' },
      { month: '5', activityGap: '1.5 - 3 hours', user: 'Robert' },

      { month: '6', activityGap: '1.5 - 3 hours', user: 'Diane' },
      { month: '7', activityGap: '3+ hours', user: 'Casey' },
      { month: '8', activityGap: '1.5 - 3 hours', user: 'Casey' },
      { month: '9', activityGap: '3+ hours', user: 'Sam' },
      { month: '5', activityGap: '1.5 - 3 hours', user: 'Robert' },
      { month: '6', activityGap: '1.5 - 3 hours', user: 'Diane' },
      { month: '7', activityGap: '3+ hours', user: 'Casey' },
      { month: '8', activityGap: '1.5 - 3 hours', user: 'Casey' },
      { month: '9', activityGap: '3+ hours', user: 'Sam' },
      { month: '4', activityGap: '1.5 - 3 hours', user: 'Robert' },
    ],
    isLoading: false,
  }),
  getters: {
    getFiltersApplied: state => {
      return state.filtersApplied;
    },
  },
  actions: {
    async fetchSortedPaginatedData(
      page = 1,
      direction = 'asc',
      field = 'user'
    ) {
      //so on pagination results will persist
      this.searchPage = page;
      this.searchDirection = direction;
      this.searchField = field;

      const reqBody = {
        limit: this.searchLimit,
        page,
        direction,
        field,
      };

      try {
        const response = await fetchAndSortWorkGapData(reqBody);
        this.totalRows = response.totalRows;
        this.fakeWorkGapData = response.fakeData;
      } catch (err) {
        console.error('Some fake error', err);
      }
    },
    createSeriesAndCategoriesForChart(
      monthly = true,
      splitBy = null,
      pageLimit = 10,
      pageNumber = 1,
      columnId = null,
      direction = null
    ) {
      let series = {};
      //Using constants for now
      let categories = this.createCategories(
        monthly,
        defaultStartDate,
        defaultEndDate
      );
      if (splitBy != null) {
        let optionsList = {};
        for (const item of this.baseChartData) {
          if (monthly == 1) {
            let index = Number(item.month) - 4;
            if (optionsList[item[splitBy]] === undefined) {
              optionsList[item[splitBy]] = this.createBaseSeries(
                categories,
                item[splitBy]
              );
            }
            optionsList[item[splitBy]].data[index] += 1;
          } else {
            //Day
            let randomDay = Math.floor(Math.random() * 30);
            let index = (Number(item.month) - 4) * 30 + randomDay;
            if (optionsList[item[splitBy]] === undefined) {
              optionsList[item[splitBy]] = this.createBaseSeries(
                categories,
                item[splitBy]
              );
            }
            optionsList[item[splitBy]].data[index] += 1;
          }
        }
        series = Object.entries(optionsList).map(([key, value]) => value);
      } else {
        let optionsList = {};
        optionsList['Total'] = this.createBaseSeries(categories, 'Total');
        for (const item of this.baseChartData) {
          if (monthly == 1) {
            let index = Number(item.month) - 4;
            optionsList['Total'].data[index] += 1;
          } else {
            //This is really bad, but, it will work for this ticket
            let randomDay = Math.floor(Math.random() * 30);
            let index = (Number(item.month) - 4) * 30 + randomDay;
            optionsList['Total'].data[index] += 1;
          }
        }
        series = Object.entries(optionsList).map(([key, value]) => value);
      }
      let sortSeries = series;

      if (columnId) {
        if (columnId == 'name') {
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.name;
            const valueB = b.name;
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
          //Sort by name
        } else if (columnId == 'total') {
          //Sort by Total
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.data.reduce(
              (partialSum, subA) => partialSum + subA,
              0
            );
            const valueB = b.data.reduce(
              (partialSum, subA) => partialSum + subA,
              0
            );
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        } else {
          //Everything else
          sortSeries = sortSeries.sort((a, b) => {
            const valueA = a.data[columnId];
            const valueB = b.data[columnId];
            const multiplier = direction === 'asc' ? 1 : -1;
            return typeof valueA === 'number'
              ? (valueA - valueB) * multiplier
              : valueA.localeCompare(valueB) * multiplier;
          });
        }
      }
      sortSeries = sortSeries.slice(
        (pageNumber - 1) * pageLimit,
        pageNumber * pageLimit
      );
      return {
        series: series,
        sortableSeries: sortSeries,
        categories: categories,
      };
    },
    createBaseSeries(categories, optionsName = 'Total') {
      let series = Array.apply(null, Array(categories.length)).map(function () {
        return 0;
      });
      return { name: optionsName, data: series };
    },
    createCategories(monthly, startDate, endDate) {
      let categories = [];
      let dateObject = new Date(startDate);
      let endDateObject = new Date(endDate);
      if (monthly) {
        //Month View
        while (dateObject < endDateObject) {
          categories.push([
            dateObject.toLocaleString('default', { month: 'short' }),
            dateObject.toLocaleString('default', { year: 'numeric' }),
          ]);
          dateObject = new Date(dateObject.setMonth(dateObject.getMonth() + 1));
        }
      } else {
        //Day View
        while (dateObject < endDateObject) {
          categories.push([
            dateObject.toLocaleString('default', {
              month: 'numeric',
              day: 'numeric',
            }),
          ]);
          dateObject = new Date(dateObject.setDate(dateObject.getDate() + 1));
        }
      }
      return categories;
    },
    resetFilters() {
      this.selectedUsers = [];
      this.selectedActivityGap = 0;

      this.startDate = defaultStartDate;
      this.endDate = defaultEndDate;
    },
    applyFilters(startDate, endDate, selectedUsers, selectedActivityGap) {
      this.startDate = startDate;
      this.endDate = endDate;
      this.selectedUsers = selectedUsers;
      this.selectedActivityGap = selectedActivityGap;

      this.filtersApplied = [];

      if (startDate !== defaultStartDate || endDate !== defaultEndDate) {
        this.filtersApplied.push('Date Range Applied');
      }

      if (selectedUsers && selectedUsers.length > 0) {
        this.filtersApplied.push('Users');
      }

      if (selectedActivityGap && selectedActivityGap.id) {
        this.filtersApplied.push('Activity Gap');
      }
    },
    async fetchMetrics() {
      this.isLoading = true;
      try {
        const payload = this._buildPayload();
        await Promise.all([
          this._fetchAverageTotalInsights(payload),
          this._fetchMonthlyInsights(payload),
          this._fetchHighOccurrenceDays(payload),
          this._fetchMostOccurrences(payload),
        ]);
      } catch (err) {
        console.error('Error fetching metrics:', err);
        toast.error('Error fetching metrics');
      } finally {
        this.isLoading = false;
      }
    },

    // Helper methods
    _buildPayload() {
      return {
        filter: {
          startDate: this.startDate,
          endDate: this.endDate,
          auxoUsers: this.selectedUsers.length
            ? this.selectedUsers.map(user => user.id)
            : [],
          activityGap: this.selectedActivityGap.id ?? 0,
        },
      };
    },

    async _fetchAverageTotalInsights(payload) {
      const response = await apiPost(
        getPath('stats/workgap/insights/average-total'),
        payload
      );

      this.currentMonth = response.current_total.activity_month;
      this.monthlyTotal = response.current_total.total_count;
      this.sixMonthAvg = Math.round(response.six_month_avg.avg_monthly_count);
      this.sixMonthRange = response.six_month_avg.date_range;

      this._updateComparisonMetrics(response.six_month_comparison);
    },

    _updateComparisonMetrics(data) {
      // Count comparison
      this.monthlyVS6MonthAverageCount.value = Math.abs(
        Math.round(data.difference * 100) / 100
      );
      this.monthlyVS6MonthAverageCount.colorTernary =
        data.difference > 0 ? 1 : 2;
      this.monthlyVS6MonthAverageCount.caretTernary =
        data.difference > 0 ? 1 : 2;

      // Percentage comparison
      this.monthlyVS6MonthAveragePercent.value = Math.abs(
        Math.round(data.percent_difference * 100) / 100
      );
      this.monthlyVS6MonthAveragePercent.colorTernary =
        data.percent_difference > 0 ? 1 : 2;
      this.monthlyVS6MonthAveragePercent.caretTernary =
        data.percent_difference > 0 ? 1 : 2;

      this.lastMonth = data.last_month;
    },

    async _fetchMonthlyInsights(payload) {
      const response = await apiPost(
        getPath('stats/workgap/insights/monthly'),
        payload
      );

      this.monthlyInsights = response.map(this._formatMonthlyInsight);
    },

    _formatMonthlyInsight(item) {
      return {
        key: item.type,
        value: item.total_count,
        textValue: item.activity_month,
        labelText: `${
          item.type.charAt(0).toUpperCase() + item.type.slice(1)
        } Month:`,
        colorTernary:
          item.type === 'current' ? 0 : item.type === 'highest' ? 1 : 2,
        showWarning: item.type === 'current',
      };
    },

    async _fetchHighOccurrenceDays(payload) {
      const response = await apiPost(
        getPath('stats/workgap/insights/high-occurrence-days'),
        payload
      );

      this.highOccurrenceDays = response.map(item => ({
        value: item.total_count,
        textValue: item.weekday,
      }));
    },

    async _fetchMostOccurrences(payload) {
      const response = await apiPost(
        getPath('stats/workgap/insights/most-occurrences'),
        payload
      );

      this.mostOccurrenceUsers = response.map(item => ({
        user: `${item.first_name} ${item.last_name}`,
        smallHours: item.short_gap_count,
        largeHours: item.long_gap_count,
        total: item.total_count,
      }));
    },
    fetchAndSortData(fakeBody) {
      const { page, limit, direction, field } = fakeBody;
      const start = (page - 1) * limit;
      const getValue = item => {
        const value = item[field];
        if (field === 'time_since_last_activity') {
          return Number(value.split('min')[0]);
        }
        if (field.includes('activity')) {
          const [datePart, timePart] = value.split(' at ');
          const [month, day, year] = datePart.split(' ');
          const [time, period] = timePart.split(' ');
          let [hours, minutes] = time.split(':');
          hours = parseInt(hours);
          if (period === 'PM' && hours !== 12) hours += 12;
          if (period === 'AM' && hours === 12) hours = 0;
          return new Date(
            `${month} ${day} ${year} ${hours}:${minutes}`
          ).getTime();
        }
        return value;
      };
      const sortedData = FAKE_WORK_GAP_DATA.sort((a, b) => {
        const valueA = getValue(a);
        const valueB = getValue(b);
        const multiplier = direction === 'asc' ? 1 : -1;
        return typeof valueA === 'number'
          ? (valueA - valueB) * multiplier
          : valueA.localeCompare(valueB) * multiplier;
      });
      return {
        fakeData: sortedData.slice(start, start + limit),
        totalRows: FAKE_WORK_GAP_DATA.length,
      };
    },
  },
});
